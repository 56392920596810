
export default {
  name: 'Modal',
  props: ['title'],
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    toggleModal() {
      this.isActive = !this.isActive
    }
  }
}
